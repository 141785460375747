exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-appstore-js": () => import("./../../../src/pages/appstore.js" /* webpackChunkName: "component---src-pages-appstore-js" */),
  "component---src-pages-bfsi-js": () => import("./../../../src/pages/bfsi.js" /* webpackChunkName: "component---src-pages-bfsi-js" */),
  "component---src-pages-blogs-how-predictive-ai-is-revolutionizing-decision-making-across-various-industries-js": () => import("./../../../src/pages/blogs/How-Predictive-AI-is-Revolutionizing-Decision-Making-Across-Various-Industries.js" /* webpackChunkName: "component---src-pages-blogs-how-predictive-ai-is-revolutionizing-decision-making-across-various-industries-js" */),
  "component---src-pages-blogs-machine-learning-the-definitive-data-exploration-guide-js": () => import("./../../../src/pages/blogs/Machine-Learning-The-Definitive-Data-Exploration-Guide.js" /* webpackChunkName: "component---src-pages-blogs-machine-learning-the-definitive-data-exploration-guide-js" */),
  "component---src-pages-blogs-tailored-generative-ai-crafting-custom-solutions-for-enterprise-innovation-js": () => import("./../../../src/pages/blogs/Tailored-Generative-AI-Crafting-Custom-Solutions-for-Enterprise-Innovation.js" /* webpackChunkName: "component---src-pages-blogs-tailored-generative-ai-crafting-custom-solutions-for-enterprise-innovation-js" */),
  "component---src-pages-blogs-unleashing-the-power-of-predictive-ai-revolutionizing-ai-development-and-deployment-js": () => import("./../../../src/pages/blogs/Unleashing-the-power-of-predictive-AI-Revolutionizing-AI-Development-and-Deployment.js" /* webpackChunkName: "component---src-pages-blogs-unleashing-the-power-of-predictive-ai-revolutionizing-ai-development-and-deployment-js" */),
  "component---src-pages-build-deploy-js": () => import("./../../../src/pages/build-deploy.js" /* webpackChunkName: "component---src-pages-build-deploy-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-engineering-js": () => import("./../../../src/pages/data-engineering.js" /* webpackChunkName: "component---src-pages-data-engineering-js" */),
  "component---src-pages-data-governance-js": () => import("./../../../src/pages/data-governance.js" /* webpackChunkName: "component---src-pages-data-governance-js" */),
  "component---src-pages-generative-ai-js": () => import("./../../../src/pages/generative-ai.js" /* webpackChunkName: "component---src-pages-generative-ai-js" */),
  "component---src-pages-healthcare-lifesciences-js": () => import("./../../../src/pages/healthcare-lifesciences.js" /* webpackChunkName: "component---src-pages-healthcare-lifesciences-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-llm-monitoring-js": () => import("./../../../src/pages/llm-monitoring.js" /* webpackChunkName: "component---src-pages-llm-monitoring-js" */),
  "component---src-pages-logistics-js": () => import("./../../../src/pages/logistics.js" /* webpackChunkName: "component---src-pages-logistics-js" */),
  "component---src-pages-manufacturing-js": () => import("./../../../src/pages/manufacturing.js" /* webpackChunkName: "component---src-pages-manufacturing-js" */),
  "component---src-pages-model-deployment-js": () => import("./../../../src/pages/model-deployment.js" /* webpackChunkName: "component---src-pages-model-deployment-js" */),
  "component---src-pages-model-training-js": () => import("./../../../src/pages/model-training.js" /* webpackChunkName: "component---src-pages-model-training-js" */),
  "component---src-pages-monitoring-js": () => import("./../../../src/pages/monitoring.js" /* webpackChunkName: "component---src-pages-monitoring-js" */),
  "component---src-pages-optimize-finetune-js": () => import("./../../../src/pages/optimize-finetune.js" /* webpackChunkName: "component---src-pages-optimize-finetune-js" */),
  "component---src-pages-our-integrations-js": () => import("./../../../src/pages/our-integrations.js" /* webpackChunkName: "component---src-pages-our-integrations-js" */),
  "component---src-pages-platform-js": () => import("./../../../src/pages/platform.js" /* webpackChunkName: "component---src-pages-platform-js" */),
  "component---src-pages-predictive-ai-js": () => import("./../../../src/pages/predictive-ai.js" /* webpackChunkName: "component---src-pages-predictive-ai-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-retail-js": () => import("./../../../src/pages/retail.js" /* webpackChunkName: "component---src-pages-retail-js" */),
  "component---src-pages-verticals-js": () => import("./../../../src/pages/verticals.js" /* webpackChunkName: "component---src-pages-verticals-js" */)
}

